exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categoria-[id]-js": () => import("./../../../src/pages/categoria/[id].js" /* webpackChunkName: "component---src-pages-categoria-[id]-js" */),
  "component---src-pages-detalle-[id]-index-js": () => import("./../../../src/pages/detalle/[id]/index.js" /* webpackChunkName: "component---src-pages-detalle-[id]-index-js" */),
  "component---src-pages-detalle-[id]-pago-confirmacion-js": () => import("./../../../src/pages/detalle/[id]/pago/confirmacion.js" /* webpackChunkName: "component---src-pages-detalle-[id]-pago-confirmacion-js" */),
  "component---src-pages-detalle-[id]-pago-index-js": () => import("./../../../src/pages/detalle/[id]/pago/index.js" /* webpackChunkName: "component---src-pages-detalle-[id]-pago-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-perfil-js": () => import("./../../../src/pages/perfil.js" /* webpackChunkName: "component---src-pages-perfil-js" */),
  "component---src-pages-reclamaciones-js": () => import("./../../../src/pages/reclamaciones.js" /* webpackChunkName: "component---src-pages-reclamaciones-js" */),
  "component---src-pages-registrate-js": () => import("./../../../src/pages/registrate.js" /* webpackChunkName: "component---src-pages-registrate-js" */),
  "component---src-pages-terminos-js": () => import("./../../../src/pages/terminos.js" /* webpackChunkName: "component---src-pages-terminos-js" */),
  "component---src-pages-tienda-[id]-categoria-[id]-js": () => import("./../../../src/pages/tienda/[id]/categoria/[id].js" /* webpackChunkName: "component---src-pages-tienda-[id]-categoria-[id]-js" */),
  "component---src-pages-tienda-[id]-index-js": () => import("./../../../src/pages/tienda/[id]/index.js" /* webpackChunkName: "component---src-pages-tienda-[id]-index-js" */)
}

