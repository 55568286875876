export const initialState = {
  isAuthenticated: undefined,
  user: {
    id_user: '',
    names: '',
    phone: '',
    code: '',
    user_type: ''
  },
  market: {
    id_market: 1,
    market_name: 'Acho, Rimac',
    direction: '',
    lat: '-12.040105',
    lon: '-77.0169277'
  },
  filter: {
    id_category: 0,
    description: '',
    additional: ''
  },
  modal: {
    modalOpen: false
  },
  modalAuthType: 'login',
  menuProfileStep: 0,
  idQueryOrder: '',
  search: '',
  drawerCart: false
}
