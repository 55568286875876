import { Request } from '@helpers/Request'

export const categoryList = 'categoryList'

export const CategoryListQuery = async (id_user, id_market) => {
  return await Request('get-productos-categorias-app', {
    id_usuario: id_user,
    id_market: id_market
  })
}

export const categoriesListTen = 'categoriesListfor10'

export const CategoriesListTenQuery = async (id_user, id_market) => {
  return await Request('get-productos-categorias-app-v2', {
    id_usuario: id_user,
    id_market: id_market
  })
}

export const categoryById = 'categoryById'

export const CategoryByIdQuery = async id_category => {
  return await Request('get-productos-categoria', {
    id_categoria: id_category
  })
}

export const productById = 'productById'

export const ProductByIdQuery = async (id_user, id_product) => {
  return await Request('get-product-id', {
    id_usuario: +id_user,
    id_producto: id_product
  })
}

export const storeById = 'storeById'

export const StoreByIdQuery = async id => {
  return await Request('get-info-tienda-perfil', {
    id_tienda: id
  })
}

export const additionals = 'additionalsById'

export const AdditionalsQuery = async id_categoria => {
  return await Request('get-adicionales', {
    id_categoria: id_categoria
  })
}

export const mutateFavorite = 'mutateFavorite'

export const MutateFavoriteQuery = async (id_user, id_product) => {
  const response = await Request('set-favorito', {
    id_usuario: +id_user,
    id_producto: id_product
  })

  return response
}

export const ordersByIdUser = 'ordersById'

export const OrdersByIdUserQuery = async id_user => {
  return await Request('get-pedidos-cliente', {
    id_usuario: +id_user
  })
}

export const ordersById = 'ordersById'

export const OrdersByIdQuery = async id => {
  return await Request('get-pedido-v2', {
    id_pedido: id
  })
}

export const ordersByIdStatus = 'ordersByIdStatus'

export const OrdersByIdStatusQuery = async id => {
  return Request('get-pedido-historial', {
    id_pedido: id
  })
}

export const phoneCodes = 'phoneCodes'

export const PhoneCodesQuery = async () => {
  return await Request('get-codigos-paises', {})
}

export const regularDelivery = 'regularDelivery'

export const RegularDeliveryQuery = async (id_market, id_producto, cantidad) => {
  return await Request('get-horarios-delivery-regular-v4', {
    id_market,
    id_producto,
    cantidad
  })
}

export const expressDelivery = 'expressDelivery'

export const ExpressDeliveryQuery = async (id_market, id_producto, cantidad) => {
  return await Request('get-horarios-delivery-express-v3', {
    id_market,
    id_producto,
    cantidad
  })
}

export const notificationUser = 'notificationUser'

export const NotificationUserQuery = async id_user => {
  return await Request('get-notifications-user', {
    id_user: id_user
  })
}

export const termsAndConditions = 'termsAndConditions'

export const TermsAndConditionsQuery = async () => {
  return await Request('get-terminos', {})
}

export const userCoupons = 'userCoupons'

export const UserCouponsQuery = async id_user => {
  return await Request('get-usuario-cupones', {
    id_usuario: id_user
  })
}

export const FavoriteProducts = 'FavoriteProducts'

export const FavoriteProductsQuery = async id_user => {
  return await Request('get-favorito', {
    id_usuario: id_user
  })
}

export const PaymentOptions = 'PaymentOptions'

export const PaymentOptionsQuery = async () => {
  return await Request('get-formas-pago')
}
