import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import { phoneCodes, PhoneCodesQuery } from '@constant/query'
import { UserDataContext } from '@context/userContext'
import { Request } from '@helpers/Request'
import logo from '@images/logo.png'
import Loading from '@components/loader/Loading'

function SaveLocalstorage(type, value) {
  localStorage.setItem(type, JSON.stringify(value))
}

const Register = () => {
  const userData = useContext(UserDataContext)
  const { setModalAuthType, modal, setModal, setLogin } = userData
  const [selectedOption, setSelectedOption] = useState(1)
  const [placeholder, setPlaceholder] = useState('Ingrese su celular')
  const [numbers, setNumbers] = useState(9)
  const [phoneCode, setPhoneCode] = useState('+51')
  const [phone, setPhone] = useState('')
  const [names, setNames] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [dataVerification, setDataVerification] = useState(null)
  const [code, setCode] = useState('')

  const { data } = useQuery({
    queryKey: [phoneCodes],
    queryFn: () => PhoneCodesQuery()
  })

  const CreateAccount = async () => {
    setIsLoading(true)
    let tmp_phone = phone.replace(/ /g, '')
    let tmp_name = names.replace(/^\s+|\s+$/g, '')

    const response = await Request('save-user-v2', {
      action: 'new',
      id_user: '',
      names: tmp_name,
      phone: tmp_phone,
      id_codigo_pais: selectedOption
      // id_referido: referido
    })
    if (response.status && response.status === 'success') {
      setIsLoading(false)
      setStep(1)
      setDataVerification({
        phone_complete: `${code}${tmp_phone}`,
        id_user: response.data[0].id,
        names: response.data[0].nombres,
        phone: response.data[0].telefono,
        code: response.data[0].id_codigo_pais,
        user_type: response.data[0].user_type,
        is_verified: response.data[0].telefono_verificado,
        type: 'registrar'
      })
    } else {
      setIsLoading(false)
      console.log('error', response)
    }
  }

  const Confirm = async () => {
    setIsLoading(true)
    const code_verification = code.replace(/ /g, '')
    const response = await Request('verify-user-login', {
      id_user: dataVerification.id_user,
      code_verification: code_verification
    })
    if (response.status === 'success') {
      SaveLocalstorage('SessionIdMF', dataVerification.id_user)
      SaveLocalstorage('SessionType', dataVerification.user_type)
      setIsLoading(false)
      //falta el token
      //   // await Request('save-user-token', {
      //   //   id_user: dataVerification.id_user,
      //   //   token: expoToken
      //   // })
      setLogin({
        id_user: dataVerification.id_user,
        names: dataVerification.names,
        phone: dataVerification.phone,
        code: dataVerification.code,
        user_type: dataVerification.user_type
      })
      setModal(false)
    } else {
      setIsLoading(false)
      console.log('error', response)
    }
  }

  const changePlaceholder = event => {
    const selectedValue = event.target.value
    const selectedItem = data.data.find(item => {
      return item.id === Number(selectedValue)
    })
    setSelectedOption(selectedValue)
    setPhoneCode(selectedItem?.codigo || '')
    setPlaceholder(selectedItem?.placeholder || '')
    setNumbers(Number(selectedItem?.cant_digitos) || 9)
  }

  return step === 0 ? (
    <div className="flex w-full flex-col gap-0 py-10 md:flex-row md:gap-7 ">
      <img
        src={logo}
        alt="Logo"
        className="flex w-1/2 flex-col self-center rounded-xl object-contain md:w-1/2 md:flex-row md:p-10"
      />
      <div className="flex w-full flex-col justify-center gap-10 py-5 px-5 md:w-1/2">
        <div className="flex w-full flex-col gap-5 ">
          <div className="form-control w-full flex-col items-center">
            <h3 className="text-xl font-bold">Crea tu cuenta gratis</h3>
            <span>
              ¿Ya tienes cuenta?{' '}
              <button
                className="font-bold text-green-600 underline"
                onClick={() => setModalAuthType('login')}
              >
                Inicia sesión aquí
              </button>
            </span>
          </div>

          <div className="form-control ">
            <label className="label" htmlFor="codigo_celular_signup">
              <span className="label-text font-bold">Código de país</span>
            </label>
            <select
              id="codigo_celular_signup"
              name="codigo_celular_signup"
              className="select-bordered select relative w-full"
              placeholder="Selecciona tu codigo"
              value={selectedOption}
              onChange={changePlaceholder}
            >
              {data?.data?.map(item => (
                <option key={item.id} value={item.id}>
                  {item.pais} {item.codigo}
                </option>
              ))}
            </select>
          </div>

          <div className="form-control">
            <label className="label font-bold" htmlFor="celular_signup">
              <span className="label-text">Celular</span>
            </label>
            <input
              id="celular_signup"
              name="celular_signup"
              type="text"
              pattern={`[0-9]{${numbers}}`}
              maxLength={numbers}
              placeholder={placeholder}
              value={phone}
              onChange={event => {
                const input = event.target.value
                const regex = /^[0-9\b]+$/ // Expresión regular para solo aceptar números

                if (input === '' || regex.test(input)) {
                  setPhone(input)
                }
              }}
              className="input-bordered input w-full"
            />
          </div>

          <div className="form-control ">
            <label className="label font-bold" htmlFor="nombre_signup">
              <span className="label-text">Nombre completo</span>
            </label>
            <input
              id="nombre_signup"
              name="nombre_signup"
              type="text"
              value={names}
              onChange={event => setNames(event.target.value)}
              placeholder="Ingrese su nombre"
              className="input-bordered input w-full"
            />
          </div>
        </div>
        <button
          disabled={!names || !phone || phone.length < numbers}
          onClick={() => CreateAccount()}
          className="btn rounded-full bg-green-700 py-3 text-base font-bold normal-case text-white"
        >
          Enviar código de verificación
        </button>
      </div>
    </div>
  ) : (
    <div className=" flex w-full flex-col gap-7 py-10 md:flex-row">
      {isLoading && <Loading />}
      <img
        src={logo}
        alt="Logo"
        className="hidden rounded-xl object-contain p-10 md:flex md:w-1/2"
      />
      <div className="flex w-full flex-col justify-center gap-10 py-5 px-5 md:w-1/2">
        <div className="flex w-full flex-col gap-5 ">
          <div className="form-control w-full flex-col items-center gap-5">
            <h3 className="text-xl font-bold">Verifica tu cuenta</h3>
            <span>
              Ingresa el código de 6 dígitos que te enviamos por SMS al número de celular:{' '}
              <span className="font-bold text-green-700">
                {phoneCode} {phone}
              </span>
            </span>
          </div>
          <div className="form-control">
            <label className="label font-bold" htmlFor="codigo_signup">
              <span className="label-text">Código de 6 dígitos</span>
            </label>
            <input
              id="codigo_signup"
              name="codigo_signup"
              type="text"
              pattern="[0-9]{6}"
              maxLength={6}
              placeholder="Ingrese el código de 6 dígitos"
              value={code}
              onChange={event => {
                const input = event.target.value
                const regex = /^[0-9\b]+$/ // Expresión regular para solo aceptar números

                if (input === '' || regex.test(input)) {
                  setCode(input)
                }
              }}
              className="input-bordered input w-full"
            />
          </div>
        </div>
        <button
          disabled={code.length < 6}
          onClick={() => Confirm()}
          className="btn rounded-full bg-green-700 py-3 text-base font-bold normal-case text-white"
        >
          Confirmar
        </button>

        <div className="flex w-full flex-col gap-2">
          <span className="text-sm text-gray-500">
            ¿No te llega el código de verificación?
          </span>
          <ul>
            <li className="text-sm text-gray-500">
              1. Revisa que el número de celular sea el correcto.
            </li>
            <li className="text-sm text-gray-500">
              2. Solicita envío del código por WhatsApp{' '}
              <a title="Enviar codigo por WhatsApp" className="font-bold underline">
                aquí.
              </a>
            </li>
            <li className="text-sm text-gray-500">
              3. Solicita la ayuda de un asesor{' '}
              <a title="Solicitar ayuda de un asesor" className="font-bold underline">
                aquí.
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Register
